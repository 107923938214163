// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { AuthType } from "../enums";
import { WORKSPACES_WEB, CONFIG_ELEMENTS, BUILD_MODE } from "../constants";
import log from "../logging";
import { LOCALHOST_AMPLIFY_CONFIG } from "../constants/LocalHostAmplifyConfig";
import { getCognitoBypassUrlOpener } from "../helpers";
import localSetupJson from "../localTestSetup.json";
import { ConfigurationOptions } from "aws-sdk/lib/config-base";
import { Feature } from "../types/features";

export interface Oauth {
    responseType: string;
    redirectSignIn: string;
    domain: string;
    scope: string[];
    redirectSignOut: string;
    urlOpener?: (url: string) => Promise<void>;
}

export interface CustomerConfigElement {
    awsUserPoolsId: string;
    awsCognitoIdentityPoolId: string;
    awsCognitoRegion: string;
    awsUserPoolsWebClientId: string;
    oAuth: Oauth;
    portalArn: string;
    customerLandingPage: string;
    hasIdentityProvider: boolean;
    identityProviderNames?: string[];
    authenticationType: AuthType;
    isFipsEnabled?: boolean;
}

export interface AmplifyConfig {
    aws_cognito_identity_pool_id: string;
    aws_cognito_region: string;
    aws_cognito_endpoint?: string;
    aws_user_pools_id: string;
    aws_user_pools_web_client_id: string;
    oauth: Oauth;
}

export interface PortalConfig {
    portalArn: string;
    isFipsEnabled: boolean;
}

export interface ToolbarConfiguration {
    toolbarType: string;
    hiddenToolbarItems: string[];
    maxDisplayResolution: string;
    visualMode: string;
}

export interface PortalSettings {
    toolbarConfiguration?: ToolbarConfiguration;
    isCookieSyncEnabled?: boolean;
    isDeepLinkEnabled?: boolean;
}

export interface CognitoConfig {
    hasIdentityProvider: boolean;
    amplifyConfig: AmplifyConfig;
}

export interface EnvInfo {
    stage: string;
    region: string;
    loggerEndpoint: string;
}

export const DEFAULT_CLIENT_CONFIG: ConfigurationOptions = {
    // Currently, no SSR APIs use SigV4 and instead rely on OAuth tokens that are sent in the request as cookies.
    signatureVersion: "v4",
    sslEnabled: true,
    maxRetries: 1,
    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Config.html#correctClockSkew-property
    // https://aws.amazon.com/blogs/developer/clock-skew-correction/
    correctClockSkew: true,
};

export const getCognitoConfig = (): CognitoConfig => {
    let amplifyConfig: AmplifyConfig = LOCALHOST_AMPLIFY_CONFIG;

    let hasIdentityProvider = process.env.NODE_ENV === BUILD_MODE.DEVELOPMENT;

    const customerConfigElement = document.getElementById(
        CONFIG_ELEMENTS.CUSTOMER_CONFIG
    );

    if (customerConfigElement) {
        const rawConfig = JSON.parse(
            customerConfigElement.innerHTML
        ) as CustomerConfigElement;
        const oAuth = rawConfig.oAuth;
        amplifyConfig = {
            aws_cognito_identity_pool_id: rawConfig.awsCognitoIdentityPoolId,
            aws_cognito_region: rawConfig.awsCognitoRegion,
            aws_user_pools_id: rawConfig.awsUserPoolsId,
            aws_user_pools_web_client_id: rawConfig.awsUserPoolsWebClientId,
            oauth: {
                ...oAuth,
                redirectSignIn: `${oAuth.redirectSignIn[0]}sso`,
                redirectSignOut: oAuth.redirectSignOut[0],
                responseType: oAuth.responseType,
            },
            ...(rawConfig.isFipsEnabled
                ? {
                      aws_cognito_endpoint: `https://cognito-idp-fips.${rawConfig.awsCognitoRegion}.amazonaws.com/`,
                  }
                : {}),
        };
        hasIdentityProvider = rawConfig.hasIdentityProvider;

        // if we have only 1 IdP, we will bypass the Cognito Hosted UI by intercepting the urlOpener
        if (rawConfig.identityProviderNames?.length === 1) {
            amplifyConfig.oauth.urlOpener = getCognitoBypassUrlOpener(
                rawConfig.identityProviderNames[0]
            );
        }
    } else {
        log.warn(
            "Setting Cognito config for local development, can safely ignore if WebApp running locally."
        );
    }

    return { amplifyConfig, hasIdentityProvider };
};

export const getPortalConfig = (): PortalConfig => {
    const customerConfigElement = document.getElementById(
        CONFIG_ELEMENTS.CUSTOMER_CONFIG
    );
    let portalArn = localSetupJson["customer-config"].portalArn;
    let isFipsEnabled = false;

    if (customerConfigElement) {
        const rawConfig = JSON.parse(
            customerConfigElement.innerHTML
        ) as CustomerConfigElement;
        portalArn = rawConfig.portalArn;
        isFipsEnabled = rawConfig.isFipsEnabled;
    } else {
        log.warn(
            "Getting Portal config for local development, can safely ignore if WebApp running locally."
        );
    }
    return { portalArn, isFipsEnabled };
};

export const getEnvInfo = (): EnvInfo => {
    const environmentConfigElement = document.getElementById(
        CONFIG_ELEMENTS.ENV_INFO
    );

    const environmentConfig = environmentConfigElement
        ? JSON.parse(environmentConfigElement.innerHTML)
        : {};
    const {
        stage = WORKSPACES_WEB.STAGE,
        region = WORKSPACES_WEB.REGION,
        loggerEndpoint = null,
    } = environmentConfig;

    return { stage, region, loggerEndpoint };
};

export const isLocalhost = (): boolean => {
    return window.location.hostname === "localhost";
};

export const getPortalEndpoint = (): string => {
    const { stage } = getEnvInfo();
    const { portalArn, isFipsEnabled } = getPortalConfig();

    const arnParts = portalArn.split(":");
    const portalId = arnParts.slice(-1).pop()!.split("/").slice(-1).pop();
    const stageSubdomain = stage.toLowerCase() === "prod" ? "" : `.${stage}`;
    const fips = isFipsEnabled ? "-fips" : "";

    return `https://${portalId}${stageSubdomain}.workspaces-web${fips}.com`;
};

export const getAuthType = (): AuthType => {
    let authType = AuthType.STANDARD; // Default to Standard for local dev

    const customerConfigElement = document.getElementById(
        CONFIG_ELEMENTS.CUSTOMER_CONFIG
    );

    if (customerConfigElement) {
        const rawConfig = JSON.parse(
            customerConfigElement.innerHTML
        ) as CustomerConfigElement;
        authType = rawConfig.authenticationType || AuthType.STANDARD; // Default to Standard
    } else {
        log.warn(
            "Setting authType for local development, can safely ignore if WebApp running locally."
        );
    }
    log.info(`Portal authentication type is ${authType}`);
    return authType;
};

export const isFeatureEnabled = (feature: Feature): boolean => {
    if (process.env.NODE_ENV === BUILD_MODE.DEVELOPMENT) {
        console.log(
            "Getting features for local development:",
            localSetupJson.features
        );
        return localSetupJson.features?.includes(feature);
    }

    const featuresElement = document.getElementById(CONFIG_ELEMENTS.FEATURES);

    if (featuresElement) {
        const features = JSON.parse(featuresElement.innerHTML) as string[];
        return features?.includes(feature);
    }
    return false;
};

export const getToolbarConfig = (): ToolbarConfiguration => {
    const isAdminToolbarConfigEnabled = isFeatureEnabled(
        "adminConfigurableToolbar"
    );

    if (!isAdminToolbarConfigEnabled) {
        return {
            toolbarType: undefined,
            hiddenToolbarItems: [],
            maxDisplayResolution: "",
            visualMode: undefined,
        };
    }

    if (process.env.NODE_ENV === BUILD_MODE.DEVELOPMENT) {
        const settings = localSetupJson.settings as PortalSettings;
        const toolbarConfiguration = settings?.toolbarConfiguration as ToolbarConfiguration;
        console.log(
            "Getting toolbar config for local development:",
            toolbarConfiguration
        );
        return {
            toolbarType: toolbarConfiguration?.toolbarType || undefined,
            hiddenToolbarItems: toolbarConfiguration?.hiddenToolbarItems || [],
            maxDisplayResolution:
                toolbarConfiguration?.maxDisplayResolution || "",
            visualMode:
                toolbarConfiguration?.visualMode?.toLowerCase() || undefined,
        };
    }

    const settingsElement = document.getElementById(CONFIG_ELEMENTS.SETTINGS);
    const settings = JSON.parse(settingsElement.innerHTML) as PortalSettings;
    const toolbarConfiguration = settings?.toolbarConfiguration as ToolbarConfiguration;
    return {
        toolbarType: toolbarConfiguration?.toolbarType || undefined,
        hiddenToolbarItems: toolbarConfiguration?.hiddenToolbarItems || [],
        maxDisplayResolution: toolbarConfiguration?.maxDisplayResolution || "",
        visualMode:
            toolbarConfiguration?.visualMode?.toLowerCase() || undefined,
    };
};
