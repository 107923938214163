import React from "react";
import { useTranslation } from "react-i18next";
import { AppStreamEmbedConstant } from "../../../../constants";
import { isFeatureEnabled } from "../../../../configurations";
import { getMaxDisplayResolutionFromConfig } from "../../../../utils/toolbarSettingsUtils";
import { AppstreamResolutions } from "../../../../types/appStream";
import { filterDisplayResolutionItems } from "../../../../utils/displayResolutionUtils";

export const useDisplayResolutionItems = () => {
    const { t } = useTranslation();
    const isAdminToolbarConfigEnabled = isFeatureEnabled(
        "adminConfigurableToolbar"
    );

    const maxDisplayResolution = getMaxDisplayResolutionFromConfig();

    const displayResolutionItems = [
        {
            value:
                AppStreamEmbedConstant.Resolutions.KEEP_REMOTE_SERVER_DISPLAY,
            label: t(
                "toolbar.item.preference.content.tab.general.displayResolution.keepRemoteServerDisplay.label"
            ),
        },
        {
            value: AppStreamEmbedConstant.Resolutions.MATCH_LOCAL_DISPLAY,
            label: t(
                "toolbar.item.preference.content.tab.general.displayResolution.matchLocalDisplay.label"
            ),
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_800_X_600,
            label: AppstreamResolutions.SIZE_800_X_600,
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_1024_X_768,
            label: AppstreamResolutions.SIZE_1024_X_768,
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_1280_X_720,
            label: AppstreamResolutions.SIZE_1280_X_720,
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_1920_X_1080,
            label: AppstreamResolutions.SIZE_1920_X_1080,
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_2560_X_1440,
            label: AppstreamResolutions.SIZE_2560_X_1440,
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_3440_X_1440,
            label: AppstreamResolutions.SIZE_3440_X_1440,
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_3840_X_2160,
            label: AppstreamResolutions.SIZE_3840_X_2160,
        },
        {
            value: AppStreamEmbedConstant.Resolutions.SIZE_4096_X_2160,
            label: AppstreamResolutions.SIZE_4096_X_2160,
        },
    ];

    if (!isAdminToolbarConfigEnabled) {
        return displayResolutionItems;
    }

    const filteredDisplayResolutionItems = filterDisplayResolutionItems(
        maxDisplayResolution,
        displayResolutionItems
    );

    return filteredDisplayResolutionItems;
};
