import { AppStreamEmbedConstant } from "../constants";

/**
 * Calculate AS2 resolution total pixels (width x height), if it's match to local or keep remote server display, it would be 0.
 * @param resolution
 * @returns number
 */
const getResolutionPixels = (
    resolution: AppStreamEmbedConstant.Resolutions
): number => {
    if (resolution.includes("size")) {
        const resolutionArray = resolution.split("X");
        return (
            parseInt(resolutionArray[0].replace("size", "")) *
            parseInt(resolutionArray[1])
        );
    } else return 0;
};

/**
 * Compare if a certain display resolution is smaller or equal to max resolution using total pixels.
 */
export const isDisplayResolutionUnderMaxResolution = (
    displayResolution: AppStreamEmbedConstant.Resolutions,
    maxDisplayResolution: AppStreamEmbedConstant.Resolutions
): boolean => {
    const totalPixels = getResolutionPixels(displayResolution);
    const maxPixels = getResolutionPixels(maxDisplayResolution);
    return totalPixels <= maxPixels;
};

/**
 * Filter display resolution items based on max display resolution (if not set, it's default 4K)
 * @param maxDisplayResolution
 * @param displayResolutionItems
 * @returns displayResolutionItems
 */
export const filterDisplayResolutionItems = (
    maxDisplayResolution: AppStreamEmbedConstant.Resolutions,
    displayResolutionItems: any[]
) => {
    return displayResolutionItems.filter((item) =>
        isDisplayResolutionUnderMaxResolution(item.value, maxDisplayResolution)
    );
};
