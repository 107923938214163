import { useEffect, useState } from "react";
import { useMediaDeviceStore } from "./useMediaDeviceStore";
import { AppStreamSDK } from "../utils/AppStreamSDK";
import log, { TOOLBAR_METRIC_NAME } from "../logging";
import { Interfaces } from "@amzn/photon-portal-embed-sdk";
import IPhotonDevice = Interfaces.IPhotonDevice;

const useMicrophoneDevices = (appStreamEmbed: AppStreamSDK) => {
    const [microphoneDevices, setMicrophoneDevices] = useState<IPhotonDevice[]>(
        []
    );

    const setMicrophones = useMediaDeviceStore((store) => store.setMicrophones);
    const selectedMicrophone = useMediaDeviceStore(
        (store) => store.selectedMicrophone
    );
    const setSelectedMicrophone = useMediaDeviceStore(
        (store) => store.setSelectedMicrophone
    );

    const isMicrophonePermissionAllowed = useMediaDeviceStore(
        (state) => state.isMicrophonePermissionAllowed
    );

    const setIsMicrophonePermissionAllowed = useMediaDeviceStore(
        (state) => state.setIsMicrophonePermissionAllowed
    );

    useEffect(() => {
        checkMicrophonePermission();
    }, []);

    const checkMicrophonePermission = async () => {
        try {
            const result = await navigator.permissions.query({
                name: "microphone" as PermissionName,
            });
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_SUCCESS
            );
            setIsMicrophonePermissionAllowed(result.state === "granted");

            // Listen for changes in permission state
            result.onchange = () => {
                setIsMicrophonePermissionAllowed(result.state === "granted");
            };
        } catch (error) {
            console.error("Error checking microphone permission:", error);
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_ERROR
            );
        }
    };

    const fetchMicrophoneDevices = async () => {
        try {
            const connectedMicrophones = await appStreamEmbed.getConnectedMicrophones();
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_CONNECTED_MICROPHONES_SUCCESS
            );
            setMicrophones(connectedMicrophones);
            setMicrophoneDevices(connectedMicrophones);
        } catch (error) {
            console.error(
                "Error getting connected microphones from AppStream:",
                error
            );
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_CONNECTED_MICROPHONES_ERROR
            );
        }
    };

    useEffect(() => {
        fetchMicrophoneDevices();

        const handleDeviceChange = async () => {
            await fetchMicrophoneDevices();
        };

        navigator.mediaDevices.addEventListener(
            "devicechange",
            handleDeviceChange
        );

        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                handleDeviceChange
            );
        };
    }, [isMicrophonePermissionAllowed]);

    useEffect(() => {
        const isSelectedMicrophoneAvailable = selectedMicrophone
            ? microphoneDevices.some(
                  (mic) => mic.deviceId === selectedMicrophone.deviceId
              )
            : false;

        if (
            (!selectedMicrophone || !isSelectedMicrophoneAvailable) &&
            microphoneDevices.length > 0 &&
            microphoneDevices[0].deviceId !== ""
        ) {
            setSelectedMicrophone(microphoneDevices[0]);
        }
    }, [JSON.stringify(microphoneDevices)]);

    return microphoneDevices;
};

export default useMicrophoneDevices;
