import React, { RefObject, forwardRef, useEffect, useMemo } from "react";
import {
    DockedToolbar as EucDockedToolbar,
    TOOLBAR_HEIGHT,
    TutorialAnnotationContext,
    useToolbarCollapseStore,
    useToolbarTutorialStore,
} from "@amzn/aws-euc-ui";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { useTranslation } from "react-i18next";

import { ToolbarMode } from "../../../constants/Toolbar";
import { useTutorial } from "../items/useTutorial";
import {
    getTutorialCookie,
    setTutorialCookie,
    TutorialI18nStrings,
} from "../../../utils/tutorialUtils";
import "./DockedToolbar.css";
import { useNotificationStore } from "../../../hooks/useNotificationStore";
import { getMobileDeviceFlag } from "../../../utils/toolbarItemUtils";
import { SessionStatus } from "../../../types/appStream";
import { useToolbarItems } from "../items/useToolbarItems";

interface DockedToolbarInternalProps {
    appStreamSDK: AppStreamSDK;
    toolbarState?: ToolbarMode;
}

const useSetupDockedToolbar = () => {
    const { setShowTutorial, setTutorialCompleted } = useToolbarTutorialStore();
    const isTutorialFinished = useMemo(() => getTutorialCookie(), []);
    useEffect(() => {
        if (isTutorialFinished) {
            setShowTutorial(false);
            setTutorialCompleted(true);
        }
    }, [isTutorialFinished]);
};

export const DockedToolbarInternal = ({
    appStreamSDK,
    toolbarState,
}: DockedToolbarInternalProps): React.JSX.Element => {
    const { t } = useTranslation();
    useSetupDockedToolbar();
    const dockedToolbarItems = useToolbarItems(appStreamSDK, toolbarState);
    const dockedNotificationList = useNotificationStore(
        (state) => state.notificationList
    );

    const isMobileFlagOn = useMemo(() => getMobileDeviceFlag(), []);
    const { collapsed } = useToolbarCollapseStore();

    return (
        <EucDockedToolbar
            items={dockedToolbarItems}
            isCollapsible={isMobileFlagOn}
            isCollapsed={collapsed}
            i18nStrings={{
                ariaLabelToolbar: t("toolbar.docked.ariaLabel"),
                ariaLabelExpandToolbarButton: t(
                    "toolbar.docked.expand.button.ariaLabel"
                ),
            }}
            withHotspot={true}
            dockedNotificationListProps={{
                items: dockedNotificationList,
                ariaLabel: t("notification.docked.list.ariaLabel"),
            }}
        />
    );
};

interface DockedToolbarProps extends DockedToolbarInternalProps {
    sessionState: SessionStatus;
}

export const DockedToolbar = forwardRef(function DockedToolbar(
    {
        appStreamSDK,
        toolbarState = ToolbarMode.Docked,
        sessionState,
    }: DockedToolbarProps,
    ref: RefObject<HTMLDivElement>
) {
    const tutorialI18nStrings = TutorialI18nStrings();
    const currentTutorial = useTutorial(toolbarState);

    if (sessionState === "Unknown") {
        // If the session is not started, we will put dummy div element to avoid triggering unnecessary resizing event.
        return <div style={{ height: `${TOOLBAR_HEIGHT}px` }} />;
    }

    return (
        <TutorialAnnotationContext
            tutorialI18nStrings={tutorialI18nStrings}
            currentTutorial={currentTutorial}
            onFinish={() => setTutorialCookie(true)}
        >
            <div ref={ref} className={"docked-toolbar-wrapper"}>
                <DockedToolbarInternal
                    appStreamSDK={appStreamSDK}
                    toolbarState={toolbarState}
                />
            </div>
        </TutorialAnnotationContext>
    );
});
