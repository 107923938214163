export type SessionStatus =
    | "Unknown"
    | "Reserved"
    | "Started"
    | "Disconnected"
    | "Ended";

export interface SessionState {
    sessionStatus: SessionStatus;
    sessionId: string | undefined;
}

export type SessionErrorType =
    | "None"
    | "ConnectionError"
    | "ReserveSessionError"
    | "NoApplicationError"
    | "DeployApplicationError"
    | "DisconnectWarning"
    | "InstancesUnavailableError";

export interface SessionMetric {
    averageTraffic: number;
    fps: number;
    latency: number;
    peakTraffic: number;
    traffic: number;
}

export interface StoredSessionMetric {
    fps: number[];
    latency: number[];
    traffic: number[];
}

export interface Application {
    name: string;
    displayName: string;
    iconURL: string;
    description?: string;
}

export enum AppstreamResolutions {
    SIZE_800_X_600 = "800 x 600",
    SIZE_1024_X_768 = "1024 x 768",
    SIZE_1280_X_720 = "1280 x 720",
    SIZE_1920_X_1080 = "1920 x 1080",
    SIZE_2560_X_1440 = "2560 x 1440",
    SIZE_3440_X_1440 = "3440 x 1440",
    SIZE_3840_X_2160 = "3840 x 2160",
    SIZE_4096_X_2160 = "4096 x 2160",
}
