import { useEffect, useState } from "react";
import i18n from "i18next";
import { cookieManagerInstance } from "../cookies";
import {
    CustomCookieIdentifier,
    DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS,
} from "../constants/ShortbreadConfig";
import { DEFAULT_LANGUAGE } from "../utils/localizationUtils";
import { isFeatureEnabled } from "../configurations";

const useI18nLanguage = () => {
    const [language, setLanguage] = useState(() => {
        const selectedLanguage = cookieManagerInstance.getCookieStorage(
            CustomCookieIdentifier.WSW_LANGUAGE.name
        );
        return selectedLanguage || i18n.language || DEFAULT_LANGUAGE;
    });

    useEffect(() => {
        const handleLanguageChange = (lng) => {
            setLanguage(lng);
            cookieManagerInstance.setCookieStorage(
                CustomCookieIdentifier.WSW_LANGUAGE.name,
                lng,
                DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS
            );
        };

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return { language, changeLanguage };
};

export default useI18nLanguage;
