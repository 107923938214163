import { AppStreamEmbedConstant } from "../constants";
import { ValueOf } from "../types";
import {
    AppStreamEmbed,
    EmbedConstants,
    Interfaces,
} from "@amzn/photon-portal-embed-sdk";
import { IFile } from "@amzn/aws-euc-ui";
import { Application } from "../types/appStream";

const { EmbedKeyboardLocation } = AppStreamEmbedConstant;

interface ListCatalogResponse {
    applications: Application[];
}

interface IPhotonDevice {
    label: string;
    deviceId: string;
}

interface GetConnectedMicrophonesResponse {
    microphones: IPhotonDevice[];
}

interface GetConnectedWebcamsResponse {
    webcams: IPhotonDevice[];
}

/**
 * Wrapper class for AppStream embed bundle. Since we are importing minified JavaScript file,
 * it will be easier to use a wrapper class which define the interface
 */
export class AppStreamSDK {
    appStreamEmbed: AppStreamEmbed;

    constructor(appStreamEmbed: unknown) {
        this.appStreamEmbed = appStreamEmbed as AppStreamEmbed;
    }

    performAction(
        action: ValueOf<typeof AppStreamEmbedConstant> & string,
        parameters = {},
        successCallback?,
        errorCallback?
    ) {
        this.appStreamEmbed.performAction(
            action,
            parameters,
            successCallback,
            errorCallback
        );
    }

    performActionPromise<T = void>(
        action: ValueOf<typeof AppStreamEmbedConstant> & string,
        parameters?
    ): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.appStreamEmbed.performAction(
                action,
                parameters,
                resolve,
                reject
            );
        });
    }

    async setScreenResolution(
        resolutionSize: AppStreamEmbedConstant.Resolutions,
        successCallback?,
        errorCallback?
    ) {
        await this.performAction(
            AppStreamEmbedConstant.METHOD_SET_SCREEN_RESOLUTION,
            {
                screenResolution: resolutionSize,
            },
            successCallback,
            errorCallback
        );
    }

    async setMaxDisplayResolution(
        maxDisplayResolution: AppStreamEmbedConstant.Resolutions,
        successCallback?,
        errorCallback?
    ) {
        await this.performAction(
            AppStreamEmbedConstant.METHOD_SET_MAX_DISPLAY_RESOLUTION,
            {
                screenResolution: maxDisplayResolution,
            },
            successCallback,
            errorCallback
        );
    }

    async setStreamingMode(
        streamingMode: AppStreamEmbedConstant.StreamingMode,
        successCallback?,
        errorCallback?
    ) {
        await this.performAction(
            AppStreamEmbedConstant.METHOD_SET_STREAMING_MODE,
            {
                streamingMode: streamingMode,
            },
            successCallback,
            errorCallback
        );
    }

    showSoftKeyboard() {
        this.appStreamEmbed.showSoftKeyboard();
    }

    hideSoftKeyboard() {
        this.appStreamEmbed.hideSoftKeyboard();
    }

    /**
     * There is an AppStream embed function (enterFullscreen) which
     * make the `iframe` full screen, but we need to make the root
     * element full screen to show the toolbar in the full screen mode.
     */
    enterFullscreen<T extends HTMLElement>(targetEl: T) {
        if (targetEl.requestFullscreen) {
            return targetEl.requestFullscreen();
        } else {
            if (targetEl.mozRequestFullScreen) {
                // FireFox
                targetEl.mozRequestFullScreen();
            } else if (targetEl.webkitRequestFullScreen) {
                // Webkit
                targetEl.webkitRequestFullScreen();
            } else {
                return Promise.reject(
                    new Error("Full screen is not supported")
                );
            }
            return Promise.resolve();
        }
    }

    exitFullscreen() {
        this.appStreamEmbed.exitFullscreen();
        return Promise.resolve();
    }

    addEventListener(event: EmbedConstants.Events, callback: unknown) {
        this.appStreamEmbed.addEventListener(event, callback);
    }

    removeEventListener(name: EmbedConstants.Events, callback: unknown) {
        this.appStreamEmbed.removeEventListener(name, callback);
    }

    sendKeys(keys: Interfaces.EmbedKeyboardKey[]) {
        this.appStreamEmbed.sendKeys(keys);
    }

    openNewChromeWindow() {
        this.appStreamEmbed.sendKeys([
            {
                key: "Control",
                location: EmbedKeyboardLocation.DOM_KEY_LOCATION_LEFT,
            },
            {
                key: "n",
                location: EmbedKeyboardLocation.DOM_KEY_LOCATION_STANDARD,
            },
        ]);
    }

    closeWindow() {
        this.appStreamEmbed.sendKeys([
            {
                key: "Alt",
                location: EmbedKeyboardLocation.DOM_KEY_LOCATION_LEFT,
            },
            {
                key: "F4",
                location: EmbedKeyboardLocation.DOM_KEY_LOCATION_STANDARD,
            },
        ]);
    }

    moveChromeWindowToRight() {
        this.appStreamEmbed.sendKeys([
            {
                key: "Shift",
                location: EmbedKeyboardLocation.DOM_KEY_LOCATION_LEFT,
            },
            {
                key: "OS",
                location: EmbedKeyboardLocation.DOM_KEY_LOCATION_LEFT,
            },
            {
                key: "ArrowRight",
                location: EmbedKeyboardLocation.DOM_KEY_LOCATION_STANDARD,
            },
        ]);
    }

    endSession() {
        this.appStreamEmbed.endSession();
    }

    async listFiles(path: string): Promise<IFile[]> {
        const { files } = await this.performActionPromise<{
            files: Interfaces.IDCVFile[];
        }>(EmbedConstants.METHOD_LIST_FILES, {
            remotePath: path,
        });

        return files.map<IFile>((file) => ({
            type: file.type === "folder" ? "folder" : "file",
            path: file.path,
            lastModified: file.lastModified,
            displayName: file.displayName,
            size: {
                low: file.size.low,
                high: file.size.high,
                unsigned: file.size.unsigned,
                toString: () => file.size.toString(),
            },
            mimeType: file.mimeType,
        }));
    }

    async getApplications() {
        const response = await this.performActionPromise<ListCatalogResponse>(
            AppStreamEmbedConstant.METHOD_LIST_CATALOG
        );
        return response?.applications ?? [];
    }

    launchApp(appId: string) {
        this.appStreamEmbed.launchApp(appId);
    }

    getUserInterfaceState() {
        return this.appStreamEmbed.getUserInterfaceState();
    }

    isFileExplorerEnabled() {
        const {
            isFileDownloadAllowed,
            isFileUploadAllowed,
        } = this.getUserInterfaceState();

        return isFileDownloadAllowed || isFileUploadAllowed;
    }

    isClipboardEnabled() {
        const { isCopyAllowed, isPasteAllowed } = this.getUserInterfaceState();

        return isCopyAllowed || isPasteAllowed;
    }

    async getConnectedMicrophones() {
        const response = await this.performActionPromise<
            GetConnectedMicrophonesResponse
        >(AppStreamEmbedConstant.METHOD_GET_CONNECTED_MICROPHONES);
        return response?.microphones ?? [];
    }

    async getConnectedWebcams() {
        const response = await this.performActionPromise<
            GetConnectedWebcamsResponse
        >(AppStreamEmbedConstant.METHOD_GET_CONNECTED_WEBCAMS);
        return response?.webcams ?? [];
    }
}
