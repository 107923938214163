// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import localSetupJson from "../localTestSetup.json";
// TODO(Clean up) Need to directly import EmbedConstants
export { EmbedConstants as AppStreamEmbedConstant } from "@amzn/photon-portal-embed-sdk";

export enum ERROR_STATE {
    NONE = "success",
    ERROR = "error",
    WARNING = "warning",
    INFO = "info",
}

export const BUILD_MODE = {
    TEST: "test",
    DEVELOPMENT: "development",
    PRODUCTION: "production",
};

// WorkSpacesWeb SDK
export const WORKSPACES_WEB = {
    DEBUG: true,
    LANDING_PAGE:
        "http://demo.cloudbrowse.a2z.com.s3-website-us-west-2.amazonaws.com/",
    PRODUCT_NAME: "ermine",
    REGION: "us-west-2",
    STAGE:
        process.env.NODE_ENV === BUILD_MODE.DEVELOPMENT
            ? localSetupJson.stage
            : "beta",
};

export const CONFIG_ELEMENTS = {
    ENV_INFO: "env-info",
    CUSTOMER_CONFIG: "customer-config",
    FEATURES: "features",
    SETTINGS: "settings",
};

export const TEST_USER_AGENT_SUFFIX = "LowAngleTest";

export const NIVA_USER_AGENT_ID_BETA = "AFTGAZL";

export const NIVA_USER_AGENT_ID_GA = "AFTGAZLTC";

export enum URL_PARAMETER {
    APPS = "applications",
    LEGACY = "legacy",
    DEEPLINK = "deeplink",
    DEEP_LINKS = "deepLinks",
    DIRECT_IMPORT = "directImport",
    MOBILE_DEVICE = "mobileDevice",
}

// https://regex101.com/r/Swyc9X/2
export const URL_REG_EX =
    "^$|^(https?:\\/\\/)?(?:www\\.|(?!www))[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$|^(http?s{0,1}:\\/\\/)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\/.*)?$";
