import { useEffect, useState } from "react";
import { useMediaDeviceStore } from "./useMediaDeviceStore";
import { AppStreamSDK } from "../utils/AppStreamSDK";
import log, { TOOLBAR_METRIC_NAME } from "../logging";
import { useToolbarPreferenceStore } from "./useToolbarPreferenceStore";

const useCameraDevices = (appStreamEmbed: AppStreamSDK) => {
    const [cameraDevices, setCameraDevices] = useState([]);

    const setWebcams = useMediaDeviceStore((store) => store.setWebcams);

    const selectedWebcam = useMediaDeviceStore((store) => store.selectedWebcam);
    const setSelectedWebcam = useMediaDeviceStore(
        (store) => store.setSelectedWebcam
    );

    const isWebcamPermissionAllowed = useMediaDeviceStore(
        (state) => state.isWebcamPermissionAllowed
    );

    const setIsWebcamPermissionAllowed = useMediaDeviceStore(
        (state) => state.setIsWebcamPermissionAllowed
    );

    const setWebcamEnabled = useToolbarPreferenceStore(
        (state) => state.setWebcamEnabled
    );

    useEffect(() => {
        checkCameraPermission();
    }, []);

    const checkCameraPermission = async () => {
        try {
            const result = await navigator.permissions.query({
                name: "camera" as PermissionName,
            });
            setIsWebcamPermissionAllowed(result.state === "granted");
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_SUCCESS
            );

            // Listen for changes in permission state
            result.onchange = () => {
                setIsWebcamPermissionAllowed(result.state === "granted");
            };
        } catch (error) {
            console.error("Error checking camera permission:", error);
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_ERROR
            );
        }
    };

    const fetchCameraDevices = async () => {
        try {
            const connectedWebcams = await appStreamEmbed.getConnectedWebcams();
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_CONNECTED_WEBCAMS_SUCCESS
            );
            setWebcams(connectedWebcams);
            setCameraDevices(connectedWebcams);
        } catch (error) {
            console.error(
                "Error getting connected webcams from AppStream:",
                error
            );
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_CONNECTED_WEBCAMS_ERROR
            );
        }
    };

    useEffect(() => {
        fetchCameraDevices();

        const handleDeviceChange = async () => {
            await fetchCameraDevices();
        };

        navigator.mediaDevices.addEventListener(
            "devicechange",
            handleDeviceChange
        );

        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                handleDeviceChange
            );
        };
    }, [isWebcamPermissionAllowed]);

    useEffect(() => {
        const isSelectedWebcamAvailable = selectedWebcam
            ? cameraDevices.some(
                  (camera) => camera.deviceId === selectedWebcam.deviceId
              )
            : false;

        if (
            (!selectedWebcam || !isSelectedWebcamAvailable) &&
            cameraDevices.length > 0 &&
            cameraDevices[0].deviceId !== ""
        ) {
            !isSelectedWebcamAvailable && setWebcamEnabled(false);
            setSelectedWebcam(cameraDevices[0]);
        }
    }, [JSON.stringify(cameraDevices)]);

    return cameraDevices;
};

export default useCameraDevices;
