import { AppStreamSDK } from "../utils/AppStreamSDK";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AppStreamEmbedConstant } from "../constants";
import { SessionMetric, SessionState } from "../types/appStream";
import { useSessionMetricStore } from "./useSessionMetricStore";
import { isChromiumBasedBrowser, isMacOS } from "../utils/userAgentUtils";
import { useToolbarPreferenceStore } from "./useToolbarPreferenceStore";
import { useAppStreamApplications } from "./useAppStreamApplications";
import {
    isBrowserPermissionDenied,
    isBrowserPermissionGranted,
} from "../utils/toolbarItemUtils";
import { useSessionDataStore } from "./useSessionDataStore";
import log, { TOOLBAR_METRIC_NAME } from "../logging";
import {
    getDisplayResolutionFromCookie,
    getHiddenToolbarItemsFromConfig,
    getMaxDisplayResolutionFromConfig,
    getStreamingModeFromCookie,
    getUseCommandAsControlKeyFromCookie,
} from "../utils/toolbarSettingsUtils";
import { isFeatureEnabled } from "../configurations";
import { sendClientMessage } from "../utils/clientMessageUtils";
import { useFloatingAndToolbarNotification } from "../components/toolbar/items/notification/useNotification";
import {
    useNotificationStore,
    useNotificationToolbarItemStore,
} from "./useNotificationStore";
import { Trans, useTranslation } from "react-i18next";
import { NotificationId } from "../constants/Toolbar";
import { NotificationItem } from "@amzn/aws-euc-ui";
import {
    getDeepLinksFromUrlParams,
    getValidDeepLinks,
    removeDeepLinksFromUrlParams,
} from "../utils/deepLinkUtils";
import { DeepLinkList } from "@amzn/ermine-ssr-sdk/clients/ermineserversiderenderingservice";
import { HiddenToolbarItems } from "../enums/toolbarConfig";

const useOnStarted = (appStreamSdk: AppStreamSDK) => {
    const { t } = useTranslation();
    const addSessionTrafficFromRawData = useSessionMetricStore(
        (store) => store.addTrafficFromRawData
    );
    const updateLatestSessionMetric = useSessionMetricStore(
        (store) => store.updateLatestMetric
    );
    const setUseCommandAsControlKey = useToolbarPreferenceStore(
        (store) => store.setUseCommandAsControlKey
    );
    const setWebcamEnabled = useToolbarPreferenceStore(
        (store) => store.setWebcamEnabled
    );
    const setApplications = useAppStreamApplications(
        (store) => store.setApplications
    );
    const setCopiedTextFromRemote = useSessionDataStore(
        (store) => store.setCopiedTextFromRemote
    );
    const setSessionStartTime = useSessionDataStore(
        (store) => store.setSessionStartTime
    );
    const commandKeySettingFromCookie = getUseCommandAsControlKeyFromCookie();
    const commandKeySettingOnSessionStart =
        commandKeySettingFromCookie !== undefined
            ? commandKeySettingFromCookie
            : true; // for MacOS

    // TODO Temporarily retrying a method to activate webcam (https://issues.amazon.com/issues/LOWA-9910)
    const webcamPermissionIntervalId = useRef<number>();

    const addFloatingNotification = useNotificationStore(
        (store) => store.addNotification
    );
    const removeToolbarNotification = useNotificationToolbarItemStore(
        (store) => store.removeNotification
    );
    const removeFloatingNotification = useNotificationStore(
        (store) => store.removeNotification
    );
    const addToolbarNotification = useNotificationToolbarItemStore(
        (store) => store.addNotification
    );
    const DEEP_LINK_ACCESS_DENIED_ERROR = useFloatingAndToolbarNotification({
        header: (
            <Trans i18nKey={"notification.fail.deepLink.accessDeniedError"} />
        ),
        notificationId: NotificationId.DEEP_LINK_ACCESS_DENIED_ERROR,
        type: "error",
    });

    const DeepLinkApiErrorCallbackFn = (
        errorStatusCode: number | undefined,
        deepLinksList: DeepLinkList
    ) => {
        if (errorStatusCode === 403) {
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.DEEP_LINK_ACCESS_DENIED_ERROR
            );
            addFloatingNotification(
                DEEP_LINK_ACCESS_DENIED_ERROR.floatingToolbarNotification
            );
            addToolbarNotification(
                DEEP_LINK_ACCESS_DENIED_ERROR.toolbarNotification
            );
        }

        // Reason for having TOOLBAR_DEEP_LINK_INTERNAL_SERVER_EXCEPTION_NOTIFICATION
        // and FLOATING_TOOLBAR_DEEP_LINK_INTERNAL_SERVER_EXCEPTION_NOTIFICATION is
        // because we cannot use `useFloatingAndToolbarNotification` hook inside callback
        // function because it violates react hook calling rules.
        const TOOLBAR_DEEP_LINK_INTERNAL_SERVER_EXCEPTION_NOTIFICATION: NotificationItem = {
            onDismiss: () => {
                removeToolbarNotification(
                    NotificationId.DEEP_LINK_INTERNAL_SERVER_ERROR
                );
                // Dismiss notification from tray will dismiss floating notification as well
                removeFloatingNotification(
                    NotificationId.DEEP_LINK_INTERNAL_SERVER_ERROR
                );
            },
            header: (
                <Trans
                    i18nKey={
                        "notification.fail.deepLink.internalServerException"
                    }
                />
            ),
            content: (
                <Trans>
                    {deepLinksList.map((deeplink) => {
                        return (
                            <Trans key={`deeplink-${deeplink}`}>
                                {deeplink}
                                <br />
                            </Trans>
                        );
                    })}
                </Trans>
            ),
            type: "error",
            statusIconAriaLabel: t("notification.error.statusIconAriaLabel"),
            dismissible: true,
            dismissAriaLabel: t("notification.dismissButton.ariaLabel"),
            id: NotificationId.DEEP_LINK_INTERNAL_SERVER_ERROR,
        };
        const FLOATING_TOOLBAR_DEEP_LINK_INTERNAL_SERVER_EXCEPTION_NOTIFICATION: NotificationItem = {
            onDismiss: () => {
                removeFloatingNotification(
                    NotificationId.DEEP_LINK_INTERNAL_SERVER_ERROR
                );
            },
            header: (
                <Trans
                    i18nKey={
                        "notification.fail.deepLink.internalServerException"
                    }
                />
            ),
            content: (
                <Trans>
                    {deepLinksList.map((deeplink) => {
                        return (
                            <Trans key={`deeplink-${deeplink}`}>
                                {deeplink}
                                <br />
                            </Trans>
                        );
                    })}
                </Trans>
            ),
            type: "error",
            statusIconAriaLabel: t("notification.error.statusIconAriaLabel"),
            dismissible: true,
            dismissAriaLabel: t("notification.dismissButton.ariaLabel"),
            id: NotificationId.DEEP_LINK_INTERNAL_SERVER_ERROR,
        };

        if (errorStatusCode >= 500) {
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.DEEP_LINK_INTERNAL_SERVER_ERROR
            );
            addToolbarNotification(
                TOOLBAR_DEEP_LINK_INTERNAL_SERVER_EXCEPTION_NOTIFICATION
            );
            addFloatingNotification(
                FLOATING_TOOLBAR_DEEP_LINK_INTERNAL_SERVER_EXCEPTION_NOTIFICATION
            );
        }
    };

    const DEEP_LINK_LIMIT_EXCEEDED_ERROR = useFloatingAndToolbarNotification({
        header: (
            <Trans i18nKey={"notification.fail.deepLink.limitExceededError"} />
        ),
        notificationId: NotificationId.DEEP_LINK_LIMIT_EXCEEDED_ERROR,
        type: "error",
    });

    const DEEP_LINK_INVALID_URL_ERROR = useFloatingAndToolbarNotification({
        header: (
            <Trans
                i18nKey={"notification.fail.deepLink.validationException1"}
            />
        ),
        notificationId: NotificationId.DEEP_LINK_INVALID_URL_ERROR,
        type: "error",
    });

    const validateDeepLinksCallbackFn = (
        invalidUrl = false,
        limitExceeded = false
    ) => {
        if (invalidUrl) {
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.DEEP_LINK_INVALID_URL_ERROR
            );
            addFloatingNotification(
                DEEP_LINK_INVALID_URL_ERROR.floatingToolbarNotification
            );
            addToolbarNotification(
                DEEP_LINK_INVALID_URL_ERROR.toolbarNotification
            );
        }
        if (limitExceeded) {
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.DEEP_LINK_LIMIT_EXCEEDED_ERROR
            );
            addFloatingNotification(
                DEEP_LINK_LIMIT_EXCEEDED_ERROR.floatingToolbarNotification
            );
            addToolbarNotification(
                DEEP_LINK_LIMIT_EXCEEDED_ERROR.toolbarNotification
            );
        }
    };

    return useCallback(
        async (appStreamSdk: AppStreamSDK) => {
            const deepLinks = getDeepLinksFromUrlParams();

            if (deepLinks) {
                const validDeepLinks = getValidDeepLinks(
                    deepLinks,
                    validateDeepLinksCallbackFn
                );
                if (validDeepLinks) {
                    sendClientMessage(
                        appStreamSdk,
                        validDeepLinks,
                        DeepLinkApiErrorCallbackFn
                    );
                }
                removeDeepLinksFromUrlParams();
            }
            appStreamSdk.addEventListener(
                AppStreamEmbedConstant.Events.EVENT_SESSION_METRICS,
                (metric: SessionMetric) => {
                    addSessionTrafficFromRawData(metric);
                    updateLatestSessionMetric(metric);
                }
            );

            appStreamSdk
                .getApplications()
                .then(setApplications)
                .catch(() => {
                    // Do nothing. Will retry fetching the applications when the Windows toolbar active content is rendered
                });

            // Set command key mapping by default if the device is macOS
            if (isMacOS()) {
                try {
                    await appStreamSdk.performActionPromise(
                        AppStreamEmbedConstant.METHOD_SET_KEYBOARD_SETTINGS,
                        {
                            useCommandAsControlKey: commandKeySettingOnSessionStart,
                        }
                    );
                    setUseCommandAsControlKey(commandKeySettingOnSessionStart);
                    log.publishCounterMetric(
                        TOOLBAR_METRIC_NAME.SET_COMMAND_AS_CONTROL_KEY_DEFAULT_SUCCESS
                    );
                } catch {
                    log.publishCounterMetric(
                        TOOLBAR_METRIC_NAME.SET_COMMAND_AS_CONTROL_KEY_ERROR
                    );
                }
            }

            appStreamSdk.addEventListener(
                AppStreamEmbedConstant.Events.EVENT_COPY_FROM_REMOTE,
                (event: { clipboardText: string }) => {
                    setCopiedTextFromRemote(event.clipboardText);
                }
            );

            // AS2 supports webcam only if the browser is Chromium based.
            const hiddenToolbarItems = getHiddenToolbarItemsFromConfig();
            if (
                isChromiumBasedBrowser() &&
                !isFeatureEnabled("disableWebcam") &&
                !hiddenToolbarItems.includes(HiddenToolbarItems.WEBCAM)
            ) {
                /*
                 * We can't enable a webcam right after a session started but it will be just ignored.
                 * Need more invention, but it seems AS2 or DCV need to set up something to use webcam.
                 * For a short term solution, we will retry the `setWebcam` method until a user
                 * denies/allows the permission.
                 * TODO https://issues.amazon.com/issues/LOWA-9910
                 */
                webcamPermissionIntervalId.current = window.setInterval(
                    async () => {
                        // A user denies the permission.
                        if (await isBrowserPermissionDenied("camera")) {
                            window.clearInterval(
                                webcamPermissionIntervalId.current
                            );
                            setWebcamEnabled(false);
                            return;
                        }

                        // In dockerized Chrome, if user ignores the prompt in pre-session, we won't prompt them again in session
                        if (
                            !(await isBrowserPermissionGranted("camera")) &&
                            isFeatureEnabled("dockerizedChrome")
                        ) {
                            window.clearInterval(
                                webcamPermissionIntervalId.current
                            );
                            setWebcamEnabled(false);
                            return;
                        }

                        try {
                            await appStreamSdk.performActionPromise(
                                AppStreamEmbedConstant.METHOD_SET_WEBCAM,
                                {
                                    webcamEnabled: true,
                                }
                            );
                            // A user allows the permission.
                            setWebcamEnabled(true);
                            window.clearInterval(
                                webcamPermissionIntervalId.current
                            );
                        } catch {
                            // Do nothing. Wait for the next retries
                        }
                    },
                    2000
                );
            }

            // Set max display resolution if there's one from toolbar configuration, otherwise use default 4096x2160
            const isAdminToolbarConfigEnabled = isFeatureEnabled(
                "adminConfigurableToolbar"
            );

            if (isAdminToolbarConfigEnabled) {
                const maxDisplayResolution = getMaxDisplayResolutionFromConfig();
                try {
                    await appStreamSdk.setMaxDisplayResolution(
                        maxDisplayResolution
                    );
                    log.publishCounterMetric(
                        TOOLBAR_METRIC_NAME.SET_MAX_DISPLAY_RESOLUTION_SUCCESS
                    );
                } catch {
                    log.publishCounterMetric(
                        TOOLBAR_METRIC_NAME.SET_MAX_DISPLAY_RESOLUTION_ERROR
                    );
                }
            }

            // Set initial display resolution from cookie if exists or fall back to default value
            const initialDisplayResolution =
                getDisplayResolutionFromCookie() ||
                AppStreamEmbedConstant.Resolutions.MATCH_LOCAL_DISPLAY;

            try {
                await appStreamSdk.setScreenResolution(
                    initialDisplayResolution
                );
                log.publishCounterMetric(
                    TOOLBAR_METRIC_NAME.SET_DISPLAY_RESOLUTION_SUCCESS
                );
            } catch {
                log.publishCounterMetric(
                    TOOLBAR_METRIC_NAME.SET_DISPLAY_RESOLUTION_ERROR
                );
            }

            // Set initial streaming mode from cookie if exists or fall back to default value
            const initialStreamingMode =
                getStreamingModeFromCookie() ||
                AppStreamEmbedConstant.StreamingMode.BEST_RESPONSIVENESS;
            try {
                await appStreamSdk.setStreamingMode(initialStreamingMode);
                log.publishCounterMetric(
                    initialStreamingMode ===
                        AppStreamEmbedConstant.StreamingMode.BEST_QUALITY
                        ? TOOLBAR_METRIC_NAME.STREAMING_BEST_QUALITY_SUCCESS
                        : TOOLBAR_METRIC_NAME.STREAMING_BEST_RESPONSIVE_SUCCESS
                );
            } catch {
                log.publishCounterMetric(
                    initialStreamingMode ===
                        AppStreamEmbedConstant.StreamingMode.BEST_QUALITY
                        ? TOOLBAR_METRIC_NAME.STREAMING_BEST_QUALITY_ERROR
                        : TOOLBAR_METRIC_NAME.STREAMING_BEST_RESPONSIVE_ERROR
                );
            }

            // Emit all default initial settings metrics (set in useToolbarPreferenceStore) on session start
            if (!isMacOS()) {
                log.publishCounterMetric(
                    TOOLBAR_METRIC_NAME.SET_COMMAND_AS_META_KEY_DEFAULT_SUCCESS
                );
            }
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.STREAMING_BEST_RESPONSIVE_DEFAULT_SUCCESS
            );
            log.publishCounterMetric(TOOLBAR_METRIC_NAME.SET_DEFAULT_DARK_MODE);

            // Set session start time
            setSessionStartTime(performance.now());
        },
        [appStreamSdk]
    );
};

const useOnEnded = (appStreamSdk: AppStreamSDK) => {
    return useCallback(
        async (appStreamSdk: AppStreamSDK) => {
            // TODO add more actions
        },
        [appStreamSdk]
    );
};

const useOnDisconnected = (appStreamSdk: AppStreamSDK) => {
    return useCallback(
        async (appStreamSdk: AppStreamSDK) => {
            // TODO add more actions
        },
        [appStreamSdk]
    );
};

const NO_SESSION_STATE: SessionState = {
    sessionStatus: "Unknown",
    sessionId: undefined,
};

export const useSessionStatusListener = (appStreamSdk: AppStreamSDK) => {
    const [sessionState, setSessionState] = useState<SessionState>();
    const setSessionStatus = useSessionDataStore(
        (store) => store.setSessionStatus
    );
    const setSessionID = useSessionDataStore((store) => store.setSessionID);

    useEffect(() => {
        if (appStreamSdk == null) {
            setSessionState(NO_SESSION_STATE);
        } else {
            appStreamSdk.addEventListener(
                AppStreamEmbedConstant.Events.EVENT_SESSION_STATE_CHANGED,
                (event: SessionState) => {
                    if (event) {
                        setSessionState(event);
                        setSessionStatus(event.sessionStatus);
                    }
                }
            );
        }
    }, [appStreamSdk]);

    const onStarted = useOnStarted(appStreamSdk);
    const onEnded = useOnEnded(appStreamSdk);
    const onDisconnected = useOnDisconnected(appStreamSdk);

    useEffect(() => {
        if (appStreamSdk == null || !sessionState) {
            return;
        }

        switch (sessionState.sessionStatus) {
            case "Started":
                void onStarted(appStreamSdk);
                return;
            case "Ended":
                void onEnded(appStreamSdk);
                return;
            case "Disconnected":
                void onDisconnected(appStreamSdk);
        }
    }, [appStreamSdk, sessionState?.sessionStatus]);

    useEffect(() => {
        if (sessionState?.sessionStatus === "Started") {
            setSessionID(sessionState.sessionId);
        }
    }, [sessionState?.sessionId]);
};
